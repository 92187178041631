<template>
	<div class="filter-field filter-field-diet">
		<BaseDropdown
			:clickaway="clickaway"
			text="Ernährung"
			title="Nach Ernährung filtern"
		>
			<FilterItem
				v-for="item in diets"
				:key="item.slug"
				:current="diet"
				:item="item"
				:icon="true"
				@change="_onChange"
			/>
		</BaseDropdown>
	</div>
</template>

<script>
import FilterItem from './FilterItem';
import {mapState} from 'vuex';
import {FilterMixin} from '@/mixins';

export default {
	components: {
		FilterItem
	},
	mixins: [FilterMixin],
	props: {
		clickaway: {
			type: Boolean,
			default: true
		}
	},
	computed: mapState(['diet', 'diets']),
	methods: {
		_onChange(e) {
			const {name} = e;
			this.changeFilter('diet', name);
		}
	}
};
</script>
